@tailwind base;
@layer base {
  html {
    font-family: "Poppins", system-ui, sans-serif;
    -ms-overflow-style: none; /* Hide scrollbar for IE, Edge */
    scrollbar-width: none; /* Hide scrollbar Firefox */
  }
  ::-webkit-scrollbar { 
    display: none;  /* Hide scrollbar for Chrome, Safari and Opera */
  }
}
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.body {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

@layer utilities {
  .sidebar-container {
    @apply w-[280px] overflow-auto top-0 left-0 bottom-0 h-full flex-column border-r-[1px] border-r-[#E9ECF2];
  }
  .login-container {
    @apply relative flex items-center justify-center h-screen w-screen bg-primary-blue-50;
  }
  .header-container {
    @apply w-screen md:w-full flex py-[15px] px-[30px] sticky top-0 justify-between bg-white text-primary-black-950 border-b-[1px] border-b-[#E9ECF2] z-40;
  }
  .green-chip {
    @apply bg-chip-green-100 text-chip-green-300 border border-chip-green-300;
  }
  .blue-chip {
    @apply text-primary-blue-800 bg-primary-blue-100 border border-primary-blue-800;
  }
  .table-header {
    @apply !border !border-primary-blue-100 !text-primary-black-600;
  }
  .flex-center {
    @apply !flex !justify-center !items-center;
  }
  .flex-column {
    @apply flex flex-col;
  }
  .flex-between-center {
    @apply flex items-center justify-between;
  }
}
